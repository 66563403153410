var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("psi-page-title", {
        attrs: {
          title: _vm.page.title,
          icon: _vm.page.icon,
          breadcrumbs: _vm.breadcrumbs
        }
      }),
      _c(
        "v-card",
        { staticClass: "mt-4 pa-3", attrs: { color: "primary" } },
        [
          _vm._l(_vm.logos, function(logo, index) {
            return [
              _c(
                "div",
                { key: index },
                [
                  _c("h3", { staticClass: "white--text mb-3" }, [
                    _vm._v(_vm._s(logo.size))
                  ]),
                  _c("img", { attrs: { src: logo.url } }),
                  _c("v-text-field", {
                    attrs: { dark: "", value: logo.url, readonly: "" }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      ),
      _c(
        "v-card",
        { staticClass: "mt-4 pa-3" },
        [
          _vm._l(_vm.altLogos, function(logo, index) {
            return [
              _c(
                "div",
                { key: index },
                [
                  _c("h3", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(logo.size))
                  ]),
                  _c("img", { attrs: { src: logo.url } }),
                  _c("v-text-field", {
                    attrs: { value: logo.url, readonly: "" }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }