<template>
    <div>
        <psi-page-title
            :title="page.title"
            :icon="page.icon"
            :breadcrumbs="breadcrumbs"
        ></psi-page-title>
        <!-- Content always starts with a v-card  -->
        <v-card class="mt-4 pa-3" color="primary">
            <template v-for="(logo, index) in logos">
                <div :key="index">
                    <h3 class="white--text mb-3">{{ logo.size }}</h3>
                    <img :src="logo.url" />
                    <v-text-field
                        dark
                        :value="logo.url"
                        readonly
                    ></v-text-field>
                </div>
            </template>
        </v-card>
        <v-card class="mt-4 pa-3">
            <template v-for="(logo, index) in altLogos">
                <div :key="index">
                    <h3 class="mb-3">{{ logo.size }}</h3>
                    <img :src="logo.url" />
                    <v-text-field :value="logo.url" readonly></v-text-field>
                </div>
            </template>
        </v-card>
    </div>
</template>
<script>
export default {
    name: "application-logos",
    data() {
        return {
            logos: [
                {
                    size: "X-Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-xl.png",
                },
                {
                    size: "Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-lg.png",
                },
                {
                    size: "Medium",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-md.png",
                },
                {
                    size: "Small",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-sm.png",
                },
                {
                    size: "X-Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-xl.png",
                },
                {
                    size: "Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-lg.png",
                },
                {
                    size: "Medium",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-md.png",
                },
                {
                    size: "Small",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-sm.png",
                },
            ],
            altLogos: [
                {
                    size: "X-Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-xl.png",
                },
                {
                    size: "Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-lg.png",
                },
                {
                    size: "Medium",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-md.png",
                },
                {
                    size: "Small",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-sm.png",
                },
                {
                    size: "X-Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-alt-xl.png",
                },
                {
                    size: "Large",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-alt-lg.png",
                },
                {
                    size: "Medium",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-alt-md.png",
                },
                {
                    size: "Small",
                    url:
                        "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-mark-alt-sm.png",
                },
            ],
            page: {
                title: "Application Logos",
                icon: "mdi-image-multiple",
            },
            breadcrumbs: [
                {
                    text: "Dashboard",
                    disabled: false,
                    to: "#",
                },
                {
                    text: "Application Logos",
                    disabled: true,
                },
            ],
        };
    },
    methods: {},
    mounted() {},
};
</script>

<style scoped>
</style>
